.clients {
    padding-bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100vw;
    background: #ffffff;
    padding-top: 200px;

}

.clients-text {
    margin-bottom: 3rem;

    h1 {
      font-size: 64px;
      margin-bottom: 1rem;
      color: #1a1a1a;
    }

    p {
      font-size: 1.25rem;
      line-height: 1.8rem;
      color: #4a4a4a;
      max-width: 700px;
      margin: 0 auto;
    }
  }

  .client-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .client-image {
    width: 120px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease;
  
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  
    &:hover {
      opacity: 0.5;
    }
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
  
    img {
      width: 100px;
      margin-bottom: 1rem;
    }
  
    h2 {
      margin-bottom: 0.5rem;
    }
  
    p {
      margin-bottom: 1rem;
    }
  
    button {
      padding: 0.5rem 1rem;
      background-color: #333;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
  
      &:hover {
        background-color: #111;
      }
    }
  }

  .research-link {
    display: inline-block;
    margin-top: 2rem;
    text-decoration: none;
    color: #0070f3;
    font-weight: 500;
    transition: color 0.2s ease;
  
    &:hover {
      color: #004bbd;
      text-decoration: underline;
    }
  }
  
  
.service-landing-image {
width: 100%;
margin-bottom: -20px;
position: relative;
z-index: 1;
}

.service-landing-overlay {
position: absolute;
bottom: 0;
height: 50px;
width: 100%;
background: linear-gradient(to top, #00132a, transparent);
z-index: 2;
}      
  
