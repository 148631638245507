.recruitment-landing {
    background: $main-gradient;
}

.recruitment-landing-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 100px 0px;
    padding-bottom: 200px;
}

.recruitment-landing-left-countdown{
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;
}
.recruitment-landing-left-countdown-item {

    text-align: center;
    margin-right: 20px;
}
.recruitment-landing-left-countdown-item-text{
    font-size: 16px;
    font-weight: 300;
    color: white;
}

.recruitment-landing-left-title {
    font-size: 48px;
    color: white;
    font-weight: 600;
    padding-bottom: 20px;
}
.recruitment-landing-left-countdown-item-number {
    font-size: 48px;
    color: white;
    font-weight: 900;
    text-align: center;
}

.recruitment-landing-left-text {
    font-size: 24px;
    color: white;
    font-weight: 800;
    width: 80%;
    margin-top: 60px;
    margin-bottom: 20px;
}
.recruitment-button {
    background: $light-blue;
    border: 0;
    padding: 25px 40px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 900;
    border-radius: 3px;
    color: white;
    transition: .2s all;
    display: block;
    width: fit-content;
}
//1000px media query
@media (max-width: 1000px) {
    .recruitment-landing-content {
        flex-direction: column;
    }
    .recruitment-landing-left {
        width: 100%;
    }
    .recruitment-landing-left-text {
        width: 100%;
    }
    .recruitment-landing-left-title {
        text-align: center;
    }
    .recruitment-button {
        margin-left: auto;
        margin-right: auto;
        margin-top: -30px;
        margin-bottom: 30px;
    }
    .recruitment-landing-left-countdown{
        display: flex;
        flex-direction: row;
        margin-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
    }
    .recruitment-landing-left-text {
        text-align: center;
        width: 90%;
        margin: 10px auto;
        margin-bottom: 70px;
    }
    .recruitment-landing-right img {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
    }
}