.mailchimp {
    background-color: $dark-blue;
    display: flex;
    flex-direction: row-reverse;
}

.mailchimp img {
    width: 40%;
}
.mailchimp-right {
    display: flex;
    flex-direction: column;
    width: 55%;
    justify-content: center;
    align-items: left;

}
.mailchimp-right h1{
    color: $background-blue;
    font-size: 60px;
    font-weight: 800;
    margin-top: 0px;
    margin-bottom: 0px;
}
.mailchimp-right h3{
    color: $background-blue;
    font-size: 20px;
    font-weight: 500;
    width: 80%;
    margin-top: 0px;
    margin-bottom: 0px;

}

.mailchimp-input {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 20px;
}

.mailchimp input {
    width: 60%;
    font-family: "Avenir";
    height: 70px;
    border-radius: 3px;
    border: none;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $dark-blue;
    margin-right: 10px;
    background: $background-blue;
}
.mailchimp button {
    width: 20%;
    font-family: "Avenir";
    height: 70px;
    border-radius: 3px;
    border: none;
    font-size: 18px;
    font-weight: 800;
    color: $background-blue;
    background: $extra-light-blue;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .mailchimp-right h1{
        font-size: 50px;
    }
    .mailchimp-right h3{
        font-size: 18px;
    }
    .mailchimp input, .mailchimp button {
        height: 50px;
        font-size: 16px;
    }
}

@media (max-width: 1000px) {
    .mailchimp {
        flex-direction: column;
        align-items: center;
    }
    .mailchimp img {
        width: 100%;
    }
    .mailchimp-right {
        width: 100%;
        align-items: center;
    }
    .mailchimp-right h1{
        font-size: 60px;
        padding-top: 50px;
    }
    .mailchimp-right h3{
        font-size: 20px;
        text-align: center;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
    .mailchimp-input {
        width: 100%;

        flex-direction: column;
    }
    .mailchimp input {
        width: 90%;
        max-width: 500px;
        margin-bottom: 10px;
        text-align: center;
        padding-left: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .mailchimp button {
        width: 90%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
    }
}

@media (max-width: 500px) {
    .mailchimp-right h1{
        font-size: 40px;
    }
    .mailchimp-right h3{
        font-size: 16px;
    }

}