.training-landing{

    padding-bottom: 0;
    overflow: hidden;
}


.training-landing-container {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;

}

.training-landing-content{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 150px;
    padding-bottom: 250px;
}

.training-landing-content-left {
    width: 65%;
}
.training-landing-title{
    font-size: 96px;
    font-weight: 800;

    background: $medium-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.training-landing-subtitle{
    font-size: 20px;
    font-weight: 600;
    width: 75%;
    line-height: 32px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.training-landing-image {
    width: 35%;
}


@media screen and (max-width: 1200px) {
    .training-landing-content{
        flex-direction: column;
    }
    .training-landing-content-left {
        width: 100%;
        margin-bottom: 100px;
    }
    .training-landing-image {
        width: 80%;
    }
    .training-landing-title{
        font-size: 72px;
        width: 100%;
    }
    .training-landing-subtitle{

        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .training-landing-content {
        padding-top: 70px;
    }
    .training-landing-title{
        font-size: 60px;
        text-align: center;
    }
    .training-landing-subtitle{
        font-size: 18px;
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .training-landing-title{
        font-size: 48px;
        text-align: center;
    }
}