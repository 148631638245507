.impact {
    background: $dark-blue;
    display: flex;
}
.impact img {
    width: 45%;
    height: 100%;
    display: block;
}
.impact-right {
width: 50%;
margin-left: 5%;
display: flex;
flex-direction: column;
justify-content: center;
}
.impact-right-title {
    font-size: 40px;
    font-weight: 600;
    color: white;
}

.impact-right-numbers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 40px;
    grid-gap: 40px;
}

.impact-right-number-big {
    color: white;
    font-weight: 900;
    font-size: 72px;
    background: $light-gradient;
    width: fit-content;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.impact-right-number-text {
    font-size: 18px;
    margin-right: 2px;
    color: white;
    font-weight: 300;
}

@media (max-width: 1300px) {
    .impact {
        flex-direction: column;
    }
    .impact img {
        width: 100%;
        height: auto;
    }
    .impact-right {
        width: 100%;
        margin-left: 0;
    }
    .impact-right-numbers {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 100px;
    }
    .impact-right-title {
        text-align: center;
        padding-top: 50px;
    }
    .impact-right-number {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

}

@media (max-width: 650px) {
    .impact-right-numbers {
        grid-template-columns: 1fr;
    }
    .impact-right-number {
        margin-bottom: 40px;
    }
}

@media (max-width: 500px) {
    .impact-right-title {
        font-size: 28px;
    }
}