
.footer-top-content {
    background-color: #222;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}
.footer-top-content img{
    width: 120px;
    filter: brightness(0) invert(1);
    padding-bottom: 10px;
}

.footer-top-content p {
    text-align: center;
    width: 90%;
}

.footer-top-content a {
    text-decoration: none;
    color: white;
    border-bottom: 1px solid white;

    margin-left: 5px;
}