.modal-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
  top: 0px;
  left: 0px;
}

.modal-content {
  margin: auto;
  width: 90%;
  max-width: 800px;
  background: $background-blue;
  margin-top: 100px;
  padding: 100px 80px;
  box-shadow: 0px 0px 0px 1000px rgba($color: $dark-blue, $alpha: 0.7);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
}
.modal-content-right {
  margin-right: 120px;
}
.modal-content-left {
  margin-right: 70px;
}
.modal-content-left-title {
  font-size: 32px;
  font-weight: 800;
  padding-bottom: 60px;
}
.modal-content-left-text {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 40px;
}
.modal-content-left-email {
  font-weight: 800;
  font-size: 24px;
  padding-bottom: 40px;
}

.modal-content-left-button {
  font-size: 18px;
  font-weight: 600;
  border: 2px solid $medium-blue;
  background: none;
  color: $medium-blue;
  padding: 15px 35px;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $dark-blue;
  padding-top: -50px;
}
