
.app-content {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex; 
    justify-content: center;
    flex-direction: column;
    align-items: center;
}