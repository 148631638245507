.training-modules-container {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;
}

.training-modules-title{
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    color: $medium-blue;
}

.training-modules-subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    color: $medium-blue;

}

.training-modules-options{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
    margin-bottom: 30px;
}
.training-modules-options h4{
    margin: 10px;
    font-size: 20px;
}


.training-modules-options-select {
    width: 300px !important;
}

.training-modules-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding-top: 50px;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}

.training-modules-grid-item {
    box-shadow: 0px 0px 15px -5px #aaa;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 40px;
    position: relative;
    background: white;
    display: block;
    margin-top: 10px;
    transition: .3s all;
}

.training-modules-grid-col-title {
    padding: 10px;
    text-align: center;
    font-size: 12px;
    border-radius: 3px;
    color: white;
}

.hidden-training {
    transition: .3s all;
    background-color: $background-blue;
    filter: blur(5px) opacity(0.3);
    
}

.training-modules-grid-col {
}
.training-modules-grid-item img {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 30px;
}
.training-modules-grid-item-content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.training-modules-grid-item-content h6{
    font-size: 16px;
    font-weight: 800;
    color: $dark-blue;
}
.training-modules-grid-item-content h3{
    font-size: 24px;
    font-weight: 800;

    margin-top: 5px;
}
.restricted-text {
    font-size: 14px;
    margin-top: 15px;
}

.training-modules-grid-item-content p{
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
    width: 90%;
}

.analyst-training {
    border: 5px solid $extra-light-blue;
}
.training-modules-grid-item-content-bottom {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr;
    grid-gap: 5px;
    margin-top: 10px;
}
.training-module-done {
    //filter: blur(5px) opacity(0.6);
}
.training-modules-grid-item-content-button{
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 3px;
    padding: 10px;
    border: 1px solid $dark-blue;
    color: $dark-blue;
    font-size: 16px;
    text-decoration: none;
    font-weight: 800;
    margin-top: 30px;
    cursor: pointer;
    transition: .3s all;
}
@media screen and (max-width: 1250px) {
    .training-modules-grid {
        grid-template-columns: repeat(2, 1fr);
        max-width: 900px;
    }
    .hidden-training {
        display: none;
    }
    .training-modules-options {
        flex-direction: column;
    }
    .training-modules-options-select {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .training-modules-options h4{
        display: none;
    }
}
    
@media screen and (max-width: 800px) {
    .training-modules-title {
        font-size: 36px;
        margin-bottom: 15px;
    }
    .training-modules-grid {
        grid-template-columns: repeat(1, 1fr);
        max-width: 500px;
    }
}