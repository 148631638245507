.contact-landing {
    background: $main-gradient;
}

.contact-landing-content {
    display: flex;
    flex-direction: row;
    padding: 70px 0px;
}

.contact-landing-left {
    width: 50%;
}

.contact-landing-left-title {
    font-size: 64px;
    font-weight: 900;
    color: white;
    margin-top: 150px;
}

.contact-landing-left-text {
    font-size: 24px;
    color: white;
    margin-top: 20px;
    line-height: 48px;
}

.contact-landing-right {
    width: 35%;
    margin-left: 10%;
    background: #2D68AE;
    padding: 50px;
    border-radius: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-landing-right h2 {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    font-size: 28px;
}

.contact-landing-right label {
    padding-top: 20px;
    color: white;
    font-size: 16px;
    padding-bottom: 5px;
}

.contact-input {
    background: #E2EFFF;
    height: 70px;
    border: 0;
    padding-left: 20px;
    border-radius: 3px;
    font-size: 16px;
    font-family: "Manrope";
}
.contact-button {
    margin-top: 50px;
    background: #86BBFB;
    height: 80px;
    color: white;
    border: 0;
    padding-left: 20px;
    border-radius: 3px;
    font-size: 24px;
    font-family: "Manrope";
}

.contact-button:hover {
    cursor: pointer;
}


.contact-textarea {
    background: #E2EFFF;
    height: 300px;
    border: none;
    padding: 20px;
    border-radius: 3px;
    font-size: 16px;
    font-family: "Manrope";
}

//1000px media query
@media (max-width: 1000px) {
    .contact-landing-content {
        flex-direction: column;
    }
    .contact-landing-left {
        width: 100%;
        margin: 0;
    }
    .contact-landing-left-title {
        font-size: 48px;
        text-align: center;
    }
    .contact-landing-left-text {
        width: 100%;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
    }

    .contact-landing-right {
        width: 100%;
        margin-left: 0;
        padding: 0;
        margin-top: 50px;
        padding-bottom: 50px;
    }

    .contact-landing-left-title {
        margin-top: 0px;
        font-size: 48px;
    }
    .contact-landing-right h2 {
        font-size: 24px;
        padding-top: 30px;
    }
    .contact-form {
        width: 90%;
        margin: 0 auto;
    }
    
}
