.analytics-landing{
    background-color: $background-blue;
    padding-bottom: 100px;
}

.analytics-landing-container {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
}

.analytics-landing-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 100px;
}


.analytics-landing-left {
    width: 60%;
}
.analytics-landing-right {
    width: 35%;

}

.analytics-landing-title {
    font-size: 60px;
    font-weight: 800;
    background: $medium-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 30px;
    width: fit-content;
}

.analytics-landing-subtitle {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    padding-bottom: 30px;
}

@media only screen and (max-width: 1100px) {
    .analytics-landing-content {
        flex-direction: column;
    }
    .analytics-landing-left {
        width: 100%;
        margin-bottom: 100px;
    }
    .analytics-landing-right {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .analytics-landing-title {
        font-size: 48px;
        text-align: center;
    }
    .analytics-landing-subtitle {
        font-size: 18px;
        text-align: center;
    }
}
    
@media only screen and (max-width: 550px) {
    .analytics-landing-title {
        font-size: 36px;
        text-align: center;
    }
    .analytics-landing-subtitle {
        font-size: 16px;
        text-align: center;
    }
}