.apply {
    background: $background-blue;
}

.apply h1{
    font-size: 48px;
    font-weight: 800;
    background: $medium-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    width: fit-content;
}

.apply-subtitle {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    padding-bottom: 30px;
}
.application {
    background-color: white;
    border-radius: 5px;
    padding: 100px;
    margin-bottom: 100px;
    margin-top: 50px;
}
.application h2{
    font-weight: 500;
    font-size: 36px;
    color: $medium-blue;
    margin-bottom: 20px;
    text-align: center;
}

.application hr{
    border: 1px solid $light-blue;
width: 40px;
}

.application h5{
    font-size: 16px;
    color: $light-blue;
}
.application h4{
    font-size: 24px;
    font-weight: lighter;
    margin-bottom: 5px;
}

.application h6{
    font-size: 20px;
    font-weight: lighter;
    margin-top: 30px;
}

.application-section-content{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}
.application-field {
    width: 30%;
    margin-right: 20px;
    margin-top: 15px;
}

.application-field p {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 5px;
}
.application-field input {
    width: calc(100% - 10px);
    height: 50px;
    border: 1px solid #aaa;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Avenir';
    padding-left: 10px;
}

.application-update {
    background: white;
    border-radius: 5px;
    padding: 70px;
    border-right: 50px solid $medium-blue;
    margin-bottom: 50px;
}
.application-update-img {
    width: 300px;
    margin-top: 40px;
}

.application-update-title {
    font-size: 40px;
    margin-top: 10px;
}
.application-update-description {
    font-size: 20px;
    margin-top: 10px;
    font-weight: lighter;
    line-height: 30px;
    width: 80%;
    padding-bottom: 30px;
}

.application-update button {
    background: white;
    border: 2px solid $dark-blue;
    color: $dark-blue;
    padding: 15px 25px;
    border-radius: 3px;
    font-size: 16px;
}

.logout-button {
    border-radius: 999px;
    color: white;
    background: $dark-blue;
    padding: 10px 25px;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 100px;
}