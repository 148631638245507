.teams-section h1{
    font-size: 40px;
    text-align: center;
    color: $dark-blue;
    margin-top: 120px;
}

.teams {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 50px;
    margin-bottom: 100px;
}

.team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    background: #EEF6FF;
    padding: 50px;
    position: relative;
}

.team img {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 70%;
    max-height: 100px;
}
.new-team {
    background-color: $orange;
    padding: 5px 15px;
    color: white;
    border-radius: 3px;
    margin-bottom: 10px;
}

//900px media query
@media (max-width: 900px) {
    .teams {
        grid-template-columns: 1fr;
    }

}
@media (max-width: 600px) {
    .team img {
       height: 60px;
    }
}