.main-info {
  background: $background-blue;
  display: flex;
  flex-direction: row;
}

.main-info-container {
  padding: 70px 0px;
  width: 50%;
}

.main-info-left-subtitle {
  color: $light-blue;
  font-size: 16px;
  font-weight: 800;
  margin-top: 50px;
}

.main-info-left-title {
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 800;
}
.main-info-left hr {
  border: 1px solid $light-blue;
  width: 40px;
  margin-top: 5px;
}

.main-info-left-text {
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
}
.main-info-left {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-info-right {
  width: 50%;
}
.main-info-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//900px media query
@media (max-width: 1000px) {
  .main-info {
    flex-direction: column;
  }
  .main-info-container {
    width: 100%;
    padding: 0;
  }
  .main-info-left-title {
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    margin-top: 40px;
  }
  .main-info-right {
    width: 100%;
  }
  .main-info-left-text {
    text-align: center;
    margin-bottom: 100px;
  }
  .main-info-left-subtitle {
    text-align: center;
  }
  .main-info-left hr {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 500px) {
  .main-info-left-title {
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    margin-top: 60px;
    padding-bottom: 40px;
  }
  .main-info-left-text {
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    font-weight: 600;
    padding-bottom: 60px;
    text-align: center;
  }
}
