.cab-fair {
  width: 100%;
  height: 100vh;
  background: #0a1d33;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cab-title {
  font-size: 150px;
  color: $background-blue;
  text-align: center;
  font-weight: 700;
}

.cab-subtitle {
  font-size: 24px;
  color: $background-blue;
  text-align: center;
  margin-bottom: 2rem;
}

.cab-input {
  width: 100%;
  max-width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.cab-input input {
  width: 100%;
  height: 90px;
  border: none;
  border-bottom: 2px solid $extra-light-blue;
  background: none;
  border-radius: 5px;
  font-size: 96px;
  padding-bottom: 20px;
  color: $extra-light-blue;
  margin-right: 1rem;
  font-weight: 600;
  outline: none;
}

.cab-input input::placeholder {
  color: $extra-light-blue;
  font-weight: 300;
  font-size: 36px;
}

.cab-input button {
  width: 25%;
  height: 90px;
  border: none;
  border-radius: 5px;
  background: none;
  border: $extra-light-blue 1px solid;
  color: $extra-light-blue;
  font-size: 24px;
  font-weight: 800;
  cursor: pointer;
}
