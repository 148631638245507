.consulting-contact {
    background: $main-gradient;
    padding-top: 200px;

    padding-bottom: 200px;
}
.consulting-contact-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
}
.consulting-contact-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.consulting-contact-left-title {
    font-size: 48px;
    font-weight: 800;
    color: white;
}
.consulting-contact-text {
    font-size: 18px;
    color: white;
    margin-top: 20px;
    font-weight: 300;
    width: 90%;
    line-height: 32px;
}
.consulting-contact-right {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
}
.consulting-contact-right-form-title{
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 18px;
    color: white;
}
.consulting-contact-right input{

    padding: 20px;
    border-radius: 3px;
    border: 0;
    font-family: "Avenir";
    margin-bottom: 10px;
    font-size: 16px;
}

.consulting-contact-right button {
    background: $extra-light-blue;
    color: white;
    margin-top: 40px;
    border: 0;
    padding: 20px;
    border-radius: 3px;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
}

@media (max-width: 1000px) {
    .consulting-contact {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .consulting-contact-container {
        flex-direction: column;
        align-items: center;
    }
    .consulting-contact-left {
        width: 100%;
        align-items: center;
    }
    .consulting-contact-left-title {
        font-size: 40px;
        text-align: center;
    }
    .consulting-contact-text {
        text-align: center;
        width: 100%;
    }
    .consulting-contact-right {
        width: 100%;
        margin-top: 50px;
        margin-left: 0;
    }
}