.showcase {

    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
}
.showcase-title {
    font-size: 52px;
    font-weight: 800;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background: $light-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.showcase-subtitle {
    font-size: 20px;
    font-weight: 600;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    text-align: center;
}
.showcase-container {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
}
.showcase-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    margin-top: 30px;
}

.showcase-item {
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;


}

.showcase-item-keyword {
    padding: 20px;
    background: $main-gradient;
    color: white;
    width: fit-content;
    font-size: 36px;
    font-weight: 800;
    border-radius: 5px;
}
.showcase-item-subwords {
    font-size: 36px;
    font-weight: 800;
    margin-top: 10px;
}
.showcase-item hr{
    width: 200px;
    border: 0;
    height: 0px;
    margin-top: 30px;

    background: $medium-blue;
}

.showcase-item-description {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;

    margin-bottom: 30px;
}

//800px media query
@media (max-width: 800px) {
    .showcase-title {
        font-size: 40px;
    }
    .showcase-content {
        grid-template-columns: 1fr;
    }
    .showcase-item-keyword {
        text-align: center;
        font-size: 30px;
    }
    .showcase-item-subwords {
        font-size: 20px;
    }
    .showcase-item {
        align-items: center;

    }
    .showcase-item-description {
        text-align: center;
    }
}

@media (max-width: 550px) {
    .showcase-title {
        font-size: 28px;
        margin-bottom: 15px;
    }
    .showcase-subtitle {
        font-size: 18px;

    }

}