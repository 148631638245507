.analytics-articles {
    padding-bottom: 100px;
}

.analytics-articles-title {
    font-size: 48px;
    text-align: center;
    font-weight: 800;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background: $medium-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 50px;
}


.analytics-articles-subtitle {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 50px;
}

.analytics-posts{
    display: grid;
    width: 90%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}



.analytics-post {
    display: flex;
    flex-direction: column;

    border-bottom: 10px solid $medium-blue;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
    position: relative;
}

.analytics-post img {
    width: 100%;

    object-fit: cover;
    margin-bottom: 30px;
    aspect-ratio: 16/9;
}

.analytics-post h2{
    width: 90%;
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin-left: auto;
    margin-right: auto;
}

.analytics-post h4{
    width: 90%;
    font-size: 16px;
    font-weight: 300;
    color: $dark-blue;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.analytics-post a{
    width: fit-content;
    font-size: 16px;
    font-weight: 300;
    color: $dark-blue;
    margin-left: 5%;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid $dark-blue;
    text-decoration: none;
}

@media only screen and (max-width: 1000px) {
    .analytics-posts{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 700px) {
    .analytics-posts{
        grid-template-columns: 1fr;
        max-width: 500px;
    }
}