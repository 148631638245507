.service-landing {
  padding-bottom: 0;
  overflow: hidden;
}

.service-landing-container {
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
}

.service-landing-title {
  font-size: 60px;
  font-weight: 800;
  width: 75%;
  line-height: 72px;
  padding-top: 60px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background: $medium-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.service-landing-subtitle {
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 30px;
}

.service-landing-button {
  padding: 17.5px 50px;
  background: white;
  font-weight: 800;
  border-radius: 5px;
  border: 2px solid $medium-blue;
  color: $medium-blue;
  font-size: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  cursor: pointer;
}

.service-landing-image {
  width: 100%;

  margin-bottom: -20px;
}

@media only screen and (max-width: 800px) {
  .service-landing-title {
    width: 95%;
    font-size: 42px;
    line-height: 56px;
  }
  .service-landing-subtitle {
    font-size: 18px;
    line-height: 24px;
  }
  .service-landing-button {
    font-size: 18px;
    padding: 15px 40px;
  }
}

@media only screen and (max-width: 500px) {
  .service-landing-title {
    width: 95%;
    font-size: 32px;
    line-height: 42px;
  }
  .service-landing-subtitle {
    font-size: 16px;
    line-height: 24px;
    width: 95%;
  }
  .service-landing-button {
    font-size: 16px;
    padding: 12.5px 30px;
  }
}
